import React from 'react';
import Firebase from 'firebase';
import Axios from 'axios';
import '../css/App.css';

function Board(props){
  return (
    <span>
      <button 
        onClick={() => props.onClick(props.index)} 
        disabled={!props.enabled}
      >
        {props.symbol}
      </button>
    </span>
  );
}

class App extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      turn: 'O',
      board: Array(9).fill(' ')
    }
  }

  componentDidMount(){
    const firebaseConfig = {
      apiKey: "AIzaSyDkpGUbRh829AjOjWPcXvmmolpVxXairVw",
      authDomain: "tictactoe-8a5d7.firebaseapp.com",
      databaseURL: "https://tictactoe-8a5d7.firebaseio.com",
      projectId: "tictactoe-8a5d7",
      storageBucket: "tictactoe-8a5d7.appspot.com",
      messagingSenderId: "725338734321",
      appId: "1:725338734321:web:8b60fc14c45e85105cb350"
    };

    Axios.get("https://us-central1-tictactoe-8a5d7.cloudfunctions.net/api")
      .then(res => {
        this.setState({symbol: res.data.symbol});
      })
      .catch(err => {
        console.error(err.message);
      });
    
    const db = Firebase.initializeApp(firebaseConfig).database();
    const gameRef = db.ref('game');
    gameRef.on('value', (snapshot) => {
      this.setState({
        board: snapshot.val().board,
        status: snapshot.val().status,
        turn: snapshot.val().turn
      });
    });
  }

  updateBoard = (index) => {
    //update board
    const board = [...this.state.board];
    board[index] = this.state.turn;
    Axios.post(
        "https://us-central1-tictactoe-8a5d7.cloudfunctions.net/api",
        {board: board, symbol: board[index]}
      )
      .then(() => {
        console.log("sent");
      })
      .catch(err => {
        console.error(err.message);
      });
  }

  render() {
    console.log(this.state.board);
    return (
      <div className="container" >
        {
          Object.keys(this.state.board).map(key => {
            return (
              <Board 
                symbol={this.state.board[key]} 
                key={key} 
                index={key} 
                onClick={this.updateBoard}
                enabled={this.state.turn === this.state.symbol && this.state.status === 'incomplete'}
              />)
            }
          )
        }
        {
          this.state.status === 'complete' && (
            <span>
              <h2>Winner: {this.state.turn}</h2>
              <button className="reload" onClick={() => window.location.reload()}>New Game</button>
            </span>
          )
        }
      </div>
    )
  }
}

export default App;
